var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100vw", height: "100vh", overflow: "hidden" } },
    [
      _vm.nmsb
        ? _c("img", {
            staticStyle: {
              width: "40%",
              "object-fit": "contain",
              "margin-left": "30%",
              "margin-top": "10%"
            },
            attrs: { src: require("@/assets/404_images/111.png"), alt: "" }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }