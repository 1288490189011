var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-wrap" },
    [
      _c(
        "Split",
        {
          attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
          on: {
            "update:offset": function($event) {
              return _vm.$set(_vm.split, "offset", $event)
            }
          }
        },
        [
          _c("template", { slot: "left" }, [
            _c("div", { staticClass: "group" }, [
              _c("div", { staticClass: "top" }, [
                _c(
                  "div",
                  { staticClass: "title", on: { click: _vm.onAddRole } },
                  [_vm._v("添加角色")]
                )
              ]),
              _c(
                "div",
                { staticClass: "content" },
                _vm._l(_vm.list, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "Warehouse",
                      class: { active: item.id === _vm.currentId },
                      on: {
                        click: function($event) {
                          return _vm.onChangeRole(item, index)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "ellipsis" }, [
                        _vm._v(_vm._s(item.name))
                      ]),
                      _c("div", [
                        _vm.permission("RoleModify")
                          ? _c("i", {
                              staticClass: "modify",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.modifyOrCopy(item, "modify")
                                }
                              }
                            })
                          : _vm._e(),
                        _vm.permission("RoleDel")
                          ? _c("i", {
                              staticClass: "del",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.delRoles(item)
                                }
                              }
                            })
                          : _vm._e()
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          _c("template", { slot: "right" }, [
            _c(
              "div",
              { staticClass: "model-wrap-right common-tab" },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "m-radio-group",
                    on: { change: _vm.radioChange },
                    model: {
                      value: _vm.params.radio,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "radio", $$v)
                      },
                      expression: "params.radio"
                    }
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "Role" } }, [
                      _vm._v("功能权限")
                    ]),
                    _c("el-radio-button", { attrs: { label: "Data" } }, [
                      _vm._v("数据权限")
                    ]),
                    _c("el-radio-button", { attrs: { label: "User" } }, [
                      _vm._v("关联用户")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm.params.radio === "Role"
              ? _c(
                  "div",
                  { staticStyle: { margin: "10px 20px" } },
                  [
                    _c(
                      "div",
                      { staticClass: "all-btns" },
                      [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("网页端功能权限")
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "role-btn",
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.saveRole }
                          },
                          [_vm._v("保存")]
                        )
                      ],
                      1
                    ),
                    _vm.openPanel
                      ? _c("el-tree", {
                          ref: "tree",
                          attrs: {
                            "show-checkbox": "",
                            "node-key": "name",
                            data: _vm.constantRoutes,
                            props: _vm.defaultProps,
                            "default-expanded-keys": ["all"],
                            "default-checked-keys": _vm.auths,
                            "render-content": _vm.renderContent
                          }
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "el-form-item" }, [
                      _c("label", { staticClass: "el-form-item__label" }, [
                        _vm._v("移动端功能权限")
                      ])
                    ]),
                    _vm.openPanelApp
                      ? _c("el-tree", {
                          ref: "treeApp",
                          attrs: {
                            "show-checkbox": "",
                            "node-key": "name",
                            data: _vm.appMenu,
                            props: _vm.defaultProps,
                            "default-expanded-keys": ["全部"],
                            "default-checked-keys": _vm.authApp,
                            "render-content": _vm.renderContentApp
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.params.radio === "Data"
              ? _c(
                  "div",
                  { staticStyle: { margin: "10px 20px" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "all-btns",
                        staticStyle: { "justify-content": "end" }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "role-btn",
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.saveData }
                          },
                          [_vm._v("保存")]
                        )
                      ],
                      1
                    ),
                    _vm._l(_vm.dataList, function(item) {
                      return _c(
                        "div",
                        { key: item.id },
                        [
                          _c("div", { staticStyle: { padding: "10px 0" } }, [
                            _vm._v(_vm._s(item.moduleName))
                          ]),
                          [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  data: item.submoduleList,
                                  border: "",
                                  "show-header": false
                                }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "submoduleName",
                                    label: "模块名称",
                                    width: "180"
                                  }
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "name",
                                    label: "是否全部",
                                    width: "180"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-switch", {
                                              attrs: { "active-text": "全部" },
                                              on: {
                                                change: function($event) {
                                                  return _vm.switchDataRule(
                                                    scope.row
                                                  )
                                                }
                                              },
                                              model: {
                                                value: scope.row.isAll,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "isAll",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.isAll"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "dataRuleGroupList",
                                    label: "子模块列表"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._l(
                                              scope.row.dataRuleGroupList,
                                              function(items) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: items.id,
                                                    staticStyle: {
                                                      display: "inline-block",
                                                      "padding-right": "10px"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        on: {
                                                          change: function(
                                                            val
                                                          ) {
                                                            _vm.selectCheck(
                                                              val,
                                                              scope.row
                                                            )
                                                          }
                                                        },
                                                        nativeOn: {
                                                          mouseenter: function(
                                                            $event
                                                          ) {
                                                            return _vm.enter(
                                                              items.id
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value: items.checked,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              items,
                                                              "checked",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "items.checked"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            items.ruleGroupName
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm.abnormal == items.id &&
                                                    !items.isSystem
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "12px",
                                                              color: "#607FFF",
                                                              cursor: "pointer",
                                                              display:
                                                                "inline-block",
                                                              "margin-left":
                                                                "4px"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.editCustom(
                                                                      items
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("编辑")]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  padding: "4px"
                                                                }
                                                              },
                                                              [_vm._v("|")]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.delCustom(
                                                                      items.id
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("删除")]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "red",
                                                  cursor: "pointer"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addCustomDataRuleGroup(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("自定义")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ]
                        ],
                        2
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.params.radio === "User"
              ? _c(
                  "div",
                  { staticStyle: { margin: "10px 20px" } },
                  [
                    _vm.permission("SetUser")
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.setUser()
                              }
                            }
                          },
                          [_vm._v("关联用户")]
                        )
                      : _vm._e(),
                    _c("MTable", {
                      ref: "mTableUser",
                      attrs: {
                        columns: _vm.getColumns,
                        height: _vm.height,
                        "set-data-method": _vm.getTableDatas,
                        "columns-setting": false
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "organizationId",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(_vm._s(row.organizationName))
                              ])
                            }
                          },
                          {
                            key: "phone",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(_vm._s(_vm._f("phone")(row.phone)))
                              ])
                            }
                          },
                          {
                            key: "isSystemUser",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(+row.isSystemUser === 1 ? "是" : "否")
                                )
                              ])
                            }
                          },
                          {
                            key: "action",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  row.isSystem === 0
                                    ? [
                                        _vm.permission("CancelUser")
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.cancelRoleMappingUser(
                                                      row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("取消关联")]
                                            )
                                          : _vm._e()
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        2773110157
                      )
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        2
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "设置工位" },
          on: { onOk: _vm.submitStation },
          model: {
            value: _vm.stationVisible,
            callback: function($$v) {
              _vm.stationVisible = $$v
            },
            expression: "stationVisible"
          }
        },
        [
          _c("MElTransfer", {
            attrs: {
              data: _vm.formOptions.stationList,
              props: { key: "id", label: "name" },
              titles: ["源列表", "目的列表"],
              filterable: true,
              "filter-placeholder": "请输入名称"
            },
            model: {
              value: _vm.selectStationList,
              callback: function($$v) {
                _vm.selectStationList = $$v
              },
              expression: "selectStationList"
            }
          })
        ],
        1
      ),
      _c("select-setting", {
        ref: "selectSetting",
        attrs: {
          visible: _vm.setsVisible,
          "select-materials": _vm.selectEquipment
        },
        on: {
          "update:visible": function($event) {
            _vm.setsVisible = $event
          },
          submitForm: _vm.submitSetting
        }
      }),
      _c(
        "MDialog",
        {
          attrs: { title: "查看设备" },
          on: {
            onOk: function($event) {
              _vm.setVisible = false
            }
          },
          model: {
            value: _vm.setVisible,
            callback: function($$v) {
              _vm.setVisible = $$v
            },
            expression: "setVisible"
          }
        },
        [
          _c("MTable", {
            ref: "mTables",
            attrs: {
              columns: _vm.viewSetColumns,
              height: 320,
              data: _vm.equipmentList,
              "show-page": false,
              "columns-setting": false
            },
            scopedSlots: _vm._u([
              {
                key: "drawing",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      row.drawing
                        ? _c("el-image", {
                            staticClass: "drawing-img",
                            attrs: {
                              src: row.drawing,
                              "preview-src-list": _vm.fixImg(row.drawing)
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.isSystems === 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.resetEquipment(row)
                                }
                              }
                            },
                            [_vm._v("解绑")]
                          )
                        : _c(
                            "el-button",
                            { attrs: { type: "text", disabled: "" } },
                            [_vm._v("解绑")]
                          )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c("TableDialog", {
        attrs: {
          title: "查看用户",
          show: _vm.userVisible,
          columns: _vm.viewTableColumns,
          "table-data": _vm.viewTableData
        },
        on: {
          "update:show": function($event) {
            _vm.userVisible = $event
          }
        }
      }),
      _c(
        "MDialog",
        {
          attrs: { title: "修改", width: "500px" },
          on: { onOk: _vm.submitFormUser },
          model: {
            value: _vm.visibles,
            callback: function($$v) {
              _vm.visibles = $$v
            },
            expression: "visibles"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuilds",
            attrs: {
              "label-position": "right",
              "form-data": _vm.formDataUser,
              "form-list": _vm.formLists["User"],
              "custom-rules": _vm.rules
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "筛选配置" },
          on: { onOk: _vm.submitFormFilter },
          model: {
            value: _vm.filterVisible,
            callback: function($$v) {
              _vm.filterVisible = $$v
            },
            expression: "filterVisible"
          }
        },
        [
          _c("el-input", {
            staticStyle: { width: "35%" },
            attrs: { placeholder: "请输入名称" },
            model: {
              value: _vm.ruleGroupName,
              callback: function($$v) {
                _vm.ruleGroupName = $$v
              },
              expression: "ruleGroupName"
            }
          }),
          _vm._l(_vm.modules, function(item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    refInFor: true,
                    staticStyle: { display: "flex" },
                    style: { paddingTop: index === 0 ? "15px" : "" },
                    attrs: { model: item.customForm }
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticStyle: { width: "35%" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { display: "block !important" },
                            attrs: { placeholder: "应用字段", clearable: "" },
                            on: {
                              change: function(val) {
                                _vm.changeBusinessId(val, index)
                              }
                            },
                            model: {
                              value: item.customForm.tableColumnInfoId,
                              callback: function($$v) {
                                _vm.$set(
                                  item.customForm,
                                  "tableColumnInfoId",
                                  $$v
                                )
                              },
                              expression: "item.customForm.tableColumnInfoId"
                            }
                          },
                          _vm._l(_vm.businessList, function(items) {
                            return _c("el-option", {
                              key: items.id,
                              attrs: {
                                label: items.businessName,
                                value: items.id
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticStyle: { width: "20%", "padding-left": "10px" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { display: "block !important" },
                            attrs: { placeholder: "运算符", clearable: "" },
                            on: {
                              change: function(val) {
                                _vm.changeOperator(val, item, index)
                              }
                            },
                            model: {
                              value: item.customForm.operatorName,
                              callback: function($$v) {
                                _vm.$set(item.customForm, "operatorName", $$v)
                              },
                              expression: "item.customForm.operatorName"
                            }
                          },
                          _vm._l(item.operatorList, function(items) {
                            return _c("el-option", {
                              key: items.operatorName,
                              attrs: {
                                label: items.operatorName,
                                value: items.operatorName
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticStyle: { width: "35%", "margin-left": "10px" } },
                      [
                        item.customForm.filterType === "string"
                          ? _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: item.customForm.ruleValue,
                                callback: function($$v) {
                                  _vm.$set(item.customForm, "ruleValue", $$v)
                                },
                                expression: "item.customForm.ruleValue"
                              }
                            })
                          : _vm._e(),
                        !item.isMul &&
                        (item.customForm.filterType === "ass-single-choice" ||
                          item.customForm.filterType === "ass-multiple-choice")
                          ? _c(
                              "el-select",
                              {
                                staticStyle: { display: "block !important" },
                                attrs: { placeholder: "请选择", clearable: "" },
                                model: {
                                  value: item.customForm.ruleValue,
                                  callback: function($$v) {
                                    _vm.$set(item.customForm, "ruleValue", $$v)
                                  },
                                  expression: "item.customForm.ruleValue"
                                }
                              },
                              _vm._l(item.customList, function(items) {
                                return _c("el-option", {
                                  key: items.id,
                                  attrs: { label: items.name, value: items.id }
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        item.isMul &&
                        (item.customForm.filterType === "ass-single-choice" ||
                          item.customForm.filterType === "ass-multiple-choice")
                          ? _c(
                              "el-select",
                              {
                                staticStyle: { display: "block !important" },
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  multiple: ""
                                },
                                model: {
                                  value: item.customForm.ruleValueMul,
                                  callback: function($$v) {
                                    _vm.$set(
                                      item.customForm,
                                      "ruleValueMul",
                                      $$v
                                    )
                                  },
                                  expression: "item.customForm.ruleValueMul"
                                }
                              },
                              _vm._l(item.customList, function(items) {
                                return _c("el-option", {
                                  key: items.id,
                                  attrs: { label: items.name, value: items.id }
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        item.customForm.filterType === "num"
                          ? _c("el-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: { "controls-position": "right" },
                              model: {
                                value: item.customForm.ruleValue,
                                callback: function($$v) {
                                  _vm.$set(item.customForm, "ruleValue", $$v)
                                },
                                expression: "item.customForm.ruleValue"
                              }
                            })
                          : _vm._e(),
                        item.customForm.filterType === "time"
                          ? _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "datetime",
                                placeholder: "请选择",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                format: "yyyy-MM-dd HH:mm:ss"
                              },
                              model: {
                                value: item.customForm.ruleValue,
                                callback: function($$v) {
                                  _vm.$set(item.customForm, "ruleValue", $$v)
                                },
                                expression: "item.customForm.ruleValue"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.modules.length > 1,
                          expression: "modules.length>1"
                        }
                      ],
                      staticClass: "addPro",
                      attrs: {
                        src: require("@/assets/information/procedure/remove.png"),
                        alt: "",
                        width: "16"
                      },
                      on: {
                        click: function($event) {
                          return _vm.removeThick(index)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("img", {
                staticClass: "addPro",
                staticStyle: { "margin-top": "0" },
                attrs: {
                  src: require("@/assets/information/procedure/新增2.png"),
                  alt: "",
                  width: "16"
                },
                on: { click: _vm.addThick }
              }),
              _c("span", { staticStyle: { "margin-left": "2px" } }, [
                _vm._v("且条件")
              ])
            ]
          )
        ],
        2
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "选择包含用户" },
          on: { onOk: _vm.submitUser },
          model: {
            value: _vm.setPersonVisible,
            callback: function($$v) {
              _vm.setPersonVisible = $$v
            },
            expression: "setPersonVisible"
          }
        },
        [
          _c("SelectUser", {
            ref: "selectUser",
            model: {
              value: _vm.transferSelectIds,
              callback: function($$v) {
                _vm.transferSelectIds = $$v
              },
              expression: "transferSelectIds"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }